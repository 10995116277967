<template>
	<b-sidebar
		id="asigned-project"
		:visible="isAsignedUserSidebarActive"
		backdrop
		bg-variant="white"
		no-header
		right
		shadow
		sidebar-class="sidebar-lg"
		@change="(val) => $emit('update:is-asigned-user-sidebar-active', val)"
		@hidden="resetForm"
	>
		<template #default="{ hide }">
			<!-- Header -->
			<div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
				<h5 class="mb-0">
					<feather-icon class="mr-1" icon="UserPlusIcon" size="16" />
					分配用户
				</h5>

				<feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
			</div>

			<!-- BODY -->
			<validation-observer ref="refFormObserver" #default="{ handleSubmit }">
				<!-- Form -->
				<b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)">
					<!-- User Name-->
					<validation-provider #default="{ errors }" name="User_name" rules="required">
						<b-form-group label="用户名称" label-for="user_name">
						  <b-form-select v-model="blankUserData.user_id" :state="errors.length > 0 ? false : null">
							<b-form-select-option v-for="(item, index) in userList" :key="index" :value="item.user_id">
									{{ item.user_name }}
							</b-form-select-option>
						  </b-form-select>
							<small ref="errorText1" class="text-danger">{{ errors[0] }}</small>
						</b-form-group>
					</validation-provider>
					<!-- User Role -->
					<validation-provider #default="{ errors }" name="User Role" rules="required">
						<b-form-group label="用户角色" label-for="user-role">
						  <b-form-select v-model="blankUserData.role_id">
							<b-form-select-option v-for="(item, index) in roles" :key="index" :value="item.id">
									{{ item.name }}
							</b-form-select-option>
						  </b-form-select>
							<small ref="errorText2" class="text-danger">{{ errors[0] }}</small>
						</b-form-group>
					</validation-provider>

					<!-- Form Actions -->
					<div class="d-flex mt-2">
						<b-button class="mr-1" size="sm" type="submit" variant="primary" @click="onSubmit">
							{{ $t("Submit") }}
						</b-button>
						<b-button size="sm" type="button" variant="outline-secondary" @click="hide">
							{{ $t("Cancel") }}
						</b-button>
					</div>
				</b-form>
			</validation-observer>
		</template>
	</b-sidebar>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from "vee-validate";
	import { getCurrentInstance, onMounted, reactive } from "@vue/composition-api";
	import { required } from "@validations";
	import Ripple from "vue-ripple-directive";
	import vSelect from "vue-select";
	import store from "@/store";

	export default {
		components: {
			vSelect,
			ValidationProvider,
			ValidationObserver,
		},
		directives: {
			Ripple,
		},
		model: {
			prop: "isAsignedUserSidebarActive",
			event: "update:is-asigned-user-sidebar-active",
		},
		props: {
			isAsignedUserSidebarActive: {
				type: Boolean,
				required: true,
			},

			project_id: {
				type: Number,
				required: true,
			},

			roleOptions: {
				type: [Array, Object],
				required: true,
			},
			hasUserList: {
				type: Array,
				required: true,
			},
		},
		data() {
			return {
				required,
				hasList: [],
				roles: this.roleOptions,
				userList: [],
				AlluserList: [],
			};
		},
		watch: {
			roleOptions(val) {
			  console.log('--roleOptions', val)
				this.roles = val;
			},
			hasUserList(val, oldval) {
				this.hasList = val;
				if (val) {
					// 拿到所有的用户列表
					this.userList = JSON.parse(JSON.stringify(this.AlluserList));
					this.hasUserList.forEach((ele, index) => {
						this.userList.forEach((item, i) => {
							if (ele == item.user_id) {
								this.userList.splice(i, 1);
							}
						});
					});
				}
			},
			userList(val) {
				if (val) {
					this.$emit("isAssign", this.userList);
				}
			},
		},
		setup(props, { emit }) {
			const blankUserData = reactive({
				user_id: "",
				role_id: "",
			});
			const instance = getCurrentInstance().proxy;
			onMounted(() => {
				store.dispatch("projects/GetUsers").then((response) => {
					// this.userList 是系统中所有用户的 id 数组
					instance.AlluserList = response.data;
					// 设置AlluserList是为了在添加用户后，能够再一次拿到所有的用户信息，方便后续做删减操作
					instance.userList = JSON.parse(JSON.stringify(instance.AlluserList));
					// hasUserList 是所有用户的数据信息
					instance.hasUserList.forEach((ele, index) => {
						instance.userList.forEach((item, i) => {
							if (ele == item.user_id) {
								instance.userList.splice(i, 1);
							}
						});
					});
				});
			});

			const onSubmit = () => {
				instance.$refs.refFormObserver.validate().then((success) => {
					store
						.dispatch("projects/assignProject", {
							project_id: instance.project_id,
							...blankUserData,
						})
						.then((res) => {
							if (res) {
								instance.$showToast(null, "分配成功", "您已成功将用户分配到该项目下");
								emit("refetch-data");
								emit("update:is-asigned-user-sidebar-active", false);
							}
						});
				});
			};
			const resetForm = () => {
				(blankUserData.role_id = ""), (blankUserData.user_id = "");
				instance.$refs.errorText1.innerHTML = "";
				instance.$refs.errorText2.innerHTML = "";
			};

			return {
				onSubmit,
				blankUserData,
				resetForm,
			};
		},
	};
</script>

<style lang="scss">
	@import "@core/scss/vue/libs/vue-select.scss";

	#add-new-user-sidebar {
		.vs__dropdown-menu {
			max-height: 200px !important;
		}
	}
</style>
